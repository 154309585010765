/* eslint-disable no-param-reassign */
/* eslint-disable complexity */

import _ from 'lodash';

type FormioComponent = {
    type: string;
    disabled?: boolean;
    validate?: any;
};

const MAX_LENGTH_OF_STRING = 255;
const MAX_NUMBER_FIELD_VALUE = 9999999999999.99;

// eslint-disable-next-line sonarjs/cognitive-complexity
const componentNormalize = (obj: any, isDisabledParent = false): any => {
    if (Array.isArray(obj)) {
        return obj.map((item) => componentNormalize(item, isDisabledParent));
    }

    if (typeof obj === 'object') {
        if (
            isComponent(obj) &&
            isTextInputComponent(obj) &&
            (!isDisabledParent || !obj.disabled)
        ) {
            addMaxLengthValidationForText(obj);
        }

        if (
            isComponent(obj) &&
            isNumberComponent(obj) &&
            (isDisabledParent || obj.disabled)
        ) {
            addRequireDecimal(obj);
        }

        if (
            isComponent(obj) &&
            isNumberComponent(obj) &&
            (!isDisabledParent || !obj.disabled)
        ) {
            addMaxNumberValue(obj);
            addDecimalLimit(obj);
        }

        if (
            isComponent(obj) &&
            isPhoneNumberComponent(obj) &&
            (!isDisabledParent || !obj.disabled)
        ) {
            addPhoneNumberValidation(obj);
        }

        const entries = Object.entries(obj);

        const newEntries: [string, any][] = entries
            .filter(([key]) => {
                if (isComponent(obj) && (isDisabledParent || obj.disabled)) {
                    return key !== 'validate';
                }

                return true;
            })
            .map(([key, value]) => {
                if (value) {
                    return [
                        key,
                        componentNormalize(
                            value,
                            isDisabledParent || obj.disabled,
                        ),
                    ];
                }

                return [key, value];
            });

        return Object.fromEntries(newEntries);
    }

    return obj;
};

const normalize = (obj: any, isDisabledParent = false): any => {
    const withAdditionalComponents = addAdditionalComponents(obj);

    return componentNormalize(withAdditionalComponents, isDisabledParent);
};

function isComponent(value: any): value is FormioComponent {
    if (typeof value?.type === 'string') {
        return true;
    }

    return false;
}

function isTextInputComponent(value: any): value is FormioComponent {
    if (value?.type === 'textfield' || value?.type === 'textarea') {
        return true;
    }

    return false;
}

function isNumberComponent(value: any): value is FormioComponent {
    if (value?.type === 'number') {
        return true;
    }

    return false;
}

function isPhoneNumberComponent(value: any): value is FormioComponent {
    if (value?.type === 'phoneNumber') {
        return true;
    }

    return false;
}

function addMaxLengthValidationForText(value: any) {
    if (_.has(value?.validate, 'maxLength')) return;

    const currentValidation = value?.validate || {};

    const newValidationValue = {
        ...currentValidation,
        maxLength: MAX_LENGTH_OF_STRING,
    };

    value.validate = newValidationValue;

    componentNormalize(value);
}

function addRequireDecimal(value: any) {
    value.delimiter = true;

    if (value?.decimalLimit === 0 || value.requireDecimal) return;

    value.requireDecimal = true;

    componentNormalize(value);
}

function addDecimalLimit(value: any) {
    if (value?.hasOwnProperty('decimalLimit')) return;
    value.decimalLimit = 2;

    componentNormalize(value);
}

function addMaxNumberValue(value: any) {
    if (!value?.validate?.max) {
        const currentValidation = value?.validate || {};

        const newValidationValue = {
            ...currentValidation,
            max: MAX_NUMBER_FIELD_VALUE,
        };

        value.validate = newValidationValue;

        componentNormalize(value);
    }
}

function addPhoneNumberValidation(value: any) {
    if (!value?.validate?.custom) {
        const currentValidation = value?.validate || {};

        const newValidationValue = {
            ...currentValidation,
            custom: 'if(!/^[0-9]+$/.test(input?.trim()?.replaceAll(" ", "")?.replaceAll("+", ""))) {\n  valid = t("field.digits.validation");\n} else if(input.replaceAll(" ", "").trim().length !== 13) {\n  valid = t("field.length.validation", { item: 9 });\n} else {\n  valid = true;\n}',
        };

        value.validate = newValidationValue;

        if (!value?.attributes?.maxLength) {
            const currentAttributes = value?.attributes || {};

            const newAttributesValue = {
                ...currentAttributes,
                maxLength: '9',
            };

            value.attributes = newAttributesValue;
        }

        componentNormalize(value);
    }
}

function addAdditionalComponents(form: any) {
    const formComponents = form.components;

    const additionalComponents: FormioComponent[] = [];

    return {
        ...form,
        components: [...additionalComponents, ...formComponents],
    };
}

export const FormNormalizer = {
    normalize,
};
